import React from 'react';
import { Link } from 'react-router-dom';

const ErrorContent = ({ code, message, description }) => {
	return (
		<div className="main-wrapper">
			<div className="error-box">
				<h1>{code}</h1>
				<h3 className="h2 mb-3"><i className="fa fa-warning"></i>{message}</h3>
				<p className="h4 font-weight-normal">{description}</p>
				<Link to="/" className="btn btn-primary">Back to Home</Link>
			</div>
		</div>
	);
}
export default ErrorContent;
