import React from "react";

const Home = React.lazy(() => import("./HomePage"));
const AboutUs = React.lazy(() => import("./content/AboutUsPage"));
const WhatToExpect = React.lazy(() => import("./content/WhatToExpectPage"));
const MedicalTourism = React.lazy(() =>
  import("./content/WhyMedicalTourismPage")
);
const TermsAndConditions = React.lazy(() =>
  import("./content/TermsAndConditionsPage")
);
const PrivacyPolicy = React.lazy(() => import("./content/PrivacyPolicyPage"));
const Turkey = React.lazy(() => import("./content/TurkeyPage"));
const Cities = React.lazy(() => import("./content/CitiesPage"));
const HealthProviders = React.lazy(() =>
  import("./content/HealthProvidersPage")
);
const FAQ = React.lazy(() => import("./content/FAQPage"));
const LoginContainer = React.lazy(() => import("./LoginPage"));
const Specialities = React.lazy(() => import("./SpecialitiesPage"));
const Dashboard = React.lazy(() => import("./DashboardPage"));
const Appointments = React.lazy(() => import("./AppointmentsPage"));
const AppointmentDetails = React.lazy(() => import("./AppointmentDetailsPage"));
const Password = React.lazy(() => import("./ChangePasswordPage"));
const Profile = React.lazy(() => import("../components/dashboard/profile"));
const DoctorList = React.lazy(() => import("./DoctorListPage"));
const DoctorProfile = React.lazy(() => import("./DoctorProfilePage"));

const Treatments = React.lazy(() => import("./TreatmentsPage"));
const TreatmentSummary = React.lazy(() => import("./TreatmentSummaryPage"));

const PatientDetails = React.lazy(() => import("./PatientDetailsPage"));
const ServicesSurvey = React.lazy(() => import("./ServicesSurveyPage"));
const ServicesSurveySummary = React.lazy(() =>
  import("./ServicesSurveySummaryPage")
);
const Register = React.lazy(() => import("./RegisterPage"));
const Activate = React.lazy(() => import("./ActivatePage"));
const ForgotPassword = React.lazy(() => import("./ForgotPasswordPage"));
const ResetPassword = React.lazy(() => import("./ResetPasswordPage"));

export {
  Home,
  AboutUs,
  WhatToExpect,
  HealthProviders,
  FAQ,
  MedicalTourism,
  TermsAndConditions,
  PrivacyPolicy,
  Turkey,
  Cities,
  LoginContainer,
  Specialities,
  Dashboard,
  Appointments,
  AppointmentDetails,
  Password,
  Profile,
  DoctorList,
  DoctorProfile,
  Treatments,
  TreatmentSummary,
  PatientDetails,
  ServicesSurvey,
  ServicesSurveySummary,
  Register,
  Activate,
  ForgotPassword,
  ResetPassword,
};
