import { useState } from "react";

const AlertDismissible = ({ type, boldMsg, msg }) => {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <>
        <div
          className={`row alert alert-${
            type === "error" ? "danger" : "success"
          } alert-dismissible fade show`}
          role="alert"
        >
          <div className="col-12">
            <strong>{boldMsg}</strong>
          </div>
          <div className="col-12">{msg}</div>
          <button
            type={type}
            className="close mx-2 my-n1"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShow(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </>
    );
  }
  return "";
}

export default AlertDismissible;
