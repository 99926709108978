import AuthService from "client/services/auth.service";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import AppContext from "store/app-store";
import styles from "./Header.module.css";
import YmpLogo from "./YmpLogo";
import { MenuButton, MenuCloseButton, MenuLangPicker, MenuPages } from "./menu";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [hide, setHide] = useState(true);
  const [t, i18next] = useTranslation();

  const [isFading, setIsFading] = useState(true);

  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setIsFading(false);
    }, 10000); // Set the time in milliseconds after which the fade effect will stop (e.g., 2000ms or 2 seconds)

    return () => clearTimeout(fadeTimer);
  }, []); // The empty dependency array ensures the effect runs only once after the initial render

  const logout = (e) => {
    AuthService.logout();
    props.setIsLoggedIn(false);
    navigate("/");
  };

  const onHandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  const Backdrop = (props) => {
    return (
      <div
        className={!hide ? styles.backdrop : ""}
        onClick={() => {
          setHide(true);
          onHandleCloseMenu();
        }}
      />
    );
  };

  //  useEffect(() => {
  //     TODO: refactor jquery code into proper react code
  //     $(".main-nav a").on("click", function (e) {
  //       if ($(this).parent().hasClass("has-submenu")) {
  //         e.preventDefault();
  //       }
  //       if (!$(this).hasClass("submenu")) {
  //         $("ul", $(this).parents("ul:first")).slideUp(350);
  //         $("a", $(this).parents("ul:first")).removeClass("submenu");
  //         $(this).next("ul").slideDown(350);
  //         $(this).addClass("submenu");
  //       } else if ($(this).hasClass("submenu")) {
  //         $(this).removeClass("submenu");
  //         $(this).next("ul").slideUp(350);
  //       }
  //     });
  //  }, []);

  const changeLanguage = (language) => {
    appContext.changeLang(language);
    const path = window.location.pathname
      .substring(1, window.location.pathname.length)
      .split("/");
    const newPath = [language, ...path.slice(1)].join("/");
    window.location.pathname = `/${newPath}`;
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {
        <header className={styles.header}>
          <nav className={`navbar navbar-expand-lg header-nav`}>
            <div className="navbar-header">
              <MenuButton setHide={setHide} />
              <YmpLogo
                linkClassName="navbar-brand logo"
                imageClassName="img-fluid logo-header"
              />
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <YmpLogo onClick={onHandleCloseMenu} />
                <MenuCloseButton onClick={onHandleCloseMenu} />
              </div>
              <MenuLangPicker
                onClick={onHandleCloseMenu}
                changeLang={changeLanguage}
                isMobile={true}
              />
              <MenuPages
                onClick={onHandleCloseMenu}
                isLoggedIn={props.isLoggedIn}
                onLogout={logout}
              />
            </div>
            <span className="mx-auto" hidden>
              <a
                href={`/${appContext.getLang()}/treatments`}
                class="btn btn-outline-primary mt-lg-0 mt-2"
                role="button"
                aria-pressed="true"
              >
                {t("StartNow") + "  "}
                {/* <i className={`fas fa-arrow-${appContext.getLang() === "ar" ? "left" : "right"}`}></i> */}
                <FontAwesomeIcon
                  icon={
                    appContext.getLang() === "ar"
                      ? faChevronLeft
                      : faChevronRight
                  }
                  fade={isFading ? "fade" : ""}
                />
              </a>
            </span>
            <MenuLangPicker
              onClick={onHandleCloseMenu}
              changeLang={changeLanguage}
            />
            {/* <MenuLoginSignup isLoggedIn={props.isLoggedIn} currentUser={AuthService.getCurrentUser()} logout={logout} /> */}
          </nav>
        </header>
      }
    </>
  );
};

export default Header;
