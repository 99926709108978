import AuthService from "./auth.service";

export default function authHeader() {
  const user = AuthService.getCurrentUser();
  console.log(`authHeader :: user: ${localStorage.getItem("user")?.email}`);

  if (user && user.accessToken) {
    console.log(`authHeader :: there is a token!!`);
    return { Authorization: "Bearer " + user.accessToken }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}
