export const preferenceOptions_en = [
  {
    id: "q1",
    title: "I am on a tight budget",
    description: "Affordable treatment with same high quality service",
  },
  {
    id: "q2",
    title: "I have no preference",
    description:
      " My mind is too preoccupied, tourism is not my priority at this time",
  },
  {
    id: "q3",
    title: "I prefer the beach over historic site seeing",
    description: "Antalya would be the choice for this preference",
    additionalLink: {name: "Antalya", link: "/cities/antalya"}
  },
  {
    id: "q4",
    title: "I prefer historic site seeing over the beach",
    description: "Istanbul would be the choice for this preference",
    additionalLink: {name: "Istanbul",link: "/cities/istanbul"}
  },
  {
    id: "q5",
    title: "I prefer being close to shopping centers",
    description:
      "If planning on doing same shopping; taking advantage of exchange rates and high quality products",
  },
  {
    id: "q6",
    title: "I prefer to be in close proximity to airports",
    description:
      "Quick in & out without worrying about traffic delays and tourist crowds",
  },
];
