import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const getThemeProps = (lang) => createTheme({
    direction: lang === 'ar' ? 'rtl' : 'ltr',
});

const getCache = (lang) => createCache({
    key: "muirtl",
    stylisPlugins: lang === 'ar' ? [prefixer, rtlPlugin] : [],
});

const Theme = ({lang, children}) => {
    return (
        <CacheProvider value={getCache(lang)}>
            <ThemeProvider theme={getThemeProps(lang)}>
                {children}
            </ThemeProvider>
        </CacheProvider>
    )
}

export default Theme;