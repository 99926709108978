import axios from "axios";
import { APIRequest } from "common";

const API_URL = `${process.env.REACT_APP_YMP_API}/api/v1/auth/`;

const login = (email, password) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/auth/login`;

  return APIRequest({
    url: url,
    method: "post",
    requestBody: {
      email,
      password,
    },
  });
};

const logout = () => {
  // localStorage.removeItem("user");
  localStorage.clear();
};

const register = (newUserdata) => {
  return APIRequest({
    url: API_URL + "signup",
    method: "post",
    requestBody: {
      ...newUserdata
    },
  });
};

const _register = (newUserdata) => {
  const response = {
    message: "",
    success: false,
    body: {},
  };
  return axios
    .post(API_URL + "signup", newUserdata)
    .then((res) => {
      console.log(`SUCCESS!`);
      console.log(`res: ${JSON.stringify(res)}`);
      response.success = true;
      response.message = [res?.data?.message];
      console.log(`res?.data?.message: ${JSON.stringify(res?.data?.message)}`);
    })
    .catch(function (error) {
      console.log(`error.response: ${JSON.stringify(error?.response)}`);
      console.log(
        `error.response.data.errors: ${!!error?.response?.data?.errors}`
      );
      console.log(
        `error?.response?.data?.errors.defaultMessage: ${error?.response?.data?.errors?.defaultMessage}`
      );
      const resMessage = !!error?.response?.data?.errors
        ? error?.response?.data?.errors.map((e) => e?.defaultMessage)
        : [
            error?.response?.data?.message ||
              error?.message ||
              error?.toString(),
          ];
      console.log(`AuthService :: register :: resMessage: ${resMessage}`);
      response.message = resMessage;
      response.success = false;
    })
    .then(function () {
      console.log(`== always executed ==`);
      return response;
    });
};

const getCurrentUser = () => {
  const loggedInUser = localStorage.getItem("user");
  if (loggedInUser) {
    const foundUser = JSON.parse(loggedInUser);
    return foundUser;
  }
  return "";
};

const activate = (token, email) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/auth/activate?token=${token}&email=${email}`;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const setCurrentUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const forgotPassword = (email) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/auth/forgot?email=${email}`;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const reset = (token, email) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/auth/reset?token=${token}&email=${email}`;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  setCurrentUser,
  activate,
  forgotPassword,
  reset,
};

export default AuthService;
