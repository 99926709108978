import axios from "axios";
import { APIRequest } from "common";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const API_URL = `${process.env.REACT_APP_YMP_API}/api/v1/auth/`;
const User_API_URL = `${process.env.REACT_APP_YMP_API}/api/v1/user/`;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};
const getCurrentUser = () => {
  const url = `${User_API_URL}id/${AuthService.getCurrentUser().id}`;

  return APIRequest({
    url: url,
    method: "get",
  });
};

const getUserByEmail = (email) => {
  const url = `${User_API_URL}email/${email}`;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const isUserRegistered = (email) => {
  const url = `${User_API_URL}exists/${email}`;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const updateUser = (userData) => {
  const url = `${User_API_URL + AuthService.getCurrentUser().id}`;

  return APIRequest({
    url: url,
    method: "put",
    requestBody: { ...userData },
  });
};
const updateUserByID = (userData) => {
  const url = `${User_API_URL + userData.userId}`;

  return APIRequest({
    url: url,
    method: "put",
    requestBody: { ...userData },
  });
};

const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getCurrentUser,
  getUserByEmail,
  isUserRegistered,
  updateUser,
  updateUserByID,
};

export default UserService;
