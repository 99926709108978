
import { useTranslation } from "react-i18next";

const OptionPlaceholder = ({label, loading}) => {
    const [t,] = useTranslation();

    const localizedLabel = label || t("Select");

    return (
        <option value="">
            {loading === true ? t("loading...") : localizedLabel}
        </option>
    )
}

export default OptionPlaceholder;