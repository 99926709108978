import React from "react";

export function renderRating(rating, lang) {
    const MAX_STARS = 5; // Maximum number of stars

    const filledStars = Math.floor(rating); // Number of filled stars
    const hasHalfStar = rating % 1 !== 0; // Check if there is a half star

    let stars = [];

    // Add filled stars
    for (let index = 0; index < filledStars; index++) {
        stars.push(<i key={index} className="fas fa-star filled"></i>);
    }

    // Add half star if applicable
    if (hasHalfStar) {
        stars.push(<i key="half" className={`fas fa-star-half-alt filled ${lang === "ar" ? "fa-flip-horizontal" : ""}`}></i>);
    }

    // Add empty stars
    const remainingStars = MAX_STARS - filledStars - (hasHalfStar ? 1 : 0);
    for (let index = 0; index < remainingStars; index++) {
        stars.push(<i key={`empty-${index}`} className="far fa-star"></i>);
    }

    return stars;
}
export function renderDynamicHeading(
    data,
    propertyName,
    headingType,
    headingText,
    location
) {
    const matchingProvider = data.find((c) =>
        location?.pathname?.includes(c.content_url)
    );

    if (matchingProvider && matchingProvider[propertyName]) {
        const propertyValue = matchingProvider[propertyName];
        return (
            <>
                {React.createElement(headingType, null, headingText)}
                <ul>
                    {propertyValue.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </>
        );
    } else {
        return null;
    }
}