import React from "react";
import { essentials_to_pack_01 } from "../img";
import { essentials_to_pack_02 } from "../img";
import { useLocation } from "react-router-dom";
import { providersList_en } from "../health-providers/ProvidersList_en";
import { renderDynamicHeading } from "common/helpers";

const DunyagozEn = () => {
  const location = useLocation();
  return (
    <div className="terms-content">
      <div className="terms-text">
        <h1>Dünyagöz</h1>
        <h2>A total of 4.7 stars out of 8043 google reviews</h2>
        <p>
          The Dünyagöz Hospitals Group introduced the concept of
          specialist treatment facilities in T&uuml;rkiye in 1996 and offers
          hundreds of different treatment methods in all branches of
          ophthalmology.&nbsp;
        </p>
        <p>
          Dünyagöz is present at 21 locations in T&uuml;rkiye and 9
          locations abroad, and has reached a daily capacity of 8,000 treatments
          in policlinics and 1,000 surgical interventions.&nbsp;
        </p>
        <p>
          Dünyagöz has a medical staff of 300 specialist physicians
          and academics - professors
          <br />
          and assistant professors, and a support staff of 3,500.&nbsp;
        </p>
        <p>
          Every year, Dünyagöz serves around 110.000 patients from 160
          countries.
        </p>

        {/* Certifications - Start */}
        {renderDynamicHeading(
          providersList_en,
          "certifications",
          "h3",
          "Certifications",
          location
        )}
        {/* Certifications - End */}

        {/* excellence_areas - Start */}
        {renderDynamicHeading(
          providersList_en,
          "excellence_areas",
          "h3",
          "Areas of Excellence",
          location
        )}
        {/* excellence_areas - End */}

        <div className="terms-text">
          <h2>Qualifications&nbsp;</h2>
          <ul>
            <li>
              Experienced medical staff of academic &ndash; professors and
              assistant professors &ndash; and professional specialists with the
              experience of thousands of surgical procedures,
            </li>
            <li>
              Specialized choice of most advanced golden standard technologies,
              approved by internationally recognized authorities and experienced
              ophthalmologists,&nbsp;
            </li>
            <li>Safest and most advanced technologies,&nbsp;</li>
            <li>Only FDA-certified consumables in surgery,</li>
            <li>
              Comprehensively equipped facilities to provide all diagnostics and
              treatment services under a single roof, around the clock (24/7),
            </li>
            <li>
              The FIRST to implement the biggest social responsibility project
              in ophthalmology: THE MOBILE EYE CLINIC.
            </li>
            <li>
              Orbital Surgery Center: the first and only facility in the world
              for inner eye tumor diagnosis and treatment,
            </li>
            <li>
              Constant participating in international professional events,
              keeping pace with latest treatment procedures and technologies.
            </li>
            <li>
              Introduced in T&uuml;rkiye the best and safest diagnostic and
              treatment equipment such as the Femtosecond-Laser and FDA-approved
              multifocal inner eye lenses.&nbsp;
            </li>
            <li>
              Uses individualized disposables to ensure hygienic conditions at
              the highest level, under no circumstances are disposable materials
              used twice.
            </li>
            <li>
              360 Degree Health Care Solutions such as nutritional advice for
              diabetic retinopathy patients.&nbsp;
            </li>
            <li>
              HOLISTIC Approach: patients are screened comprehensively working
              in cooperation with physicians from different branches.&nbsp;
            </li>
            <li>
              The first health provider to successfully apply bionic eyes
              &ldquo;Argus II Retinal Implant&rdquo; in T&uuml;rkiye.
            </li>
            <li>
              Uses Tri∙focal smart intraocular lenses developed over a period
              <br />
              of 6-7 year with a R&amp;D budget of almost 5 billion USD, which
              have proven
              <br />
              their effectiveness in treatment in a single surgery.
            </li>
            <li>
              Established the first Keratoconus diagnosis and treatment center
              in T&uuml;rkiye.&nbsp;
            </li>
            <li>
              The First health service provider in T&uuml;rkiye to offer
              Cataract treatment with the innovative Femtosecond Laser
              technology, at the same time as this innovative method was
              introduced in Europe.&nbsp;
            </li>
            <li>
              Anesthetic drops take only minutes so that patients can return to
              their daily routine much faster.
            </li>
            <li>
              Operates T&uuml;rkiye's best-equipped ocular oncology
              center.&nbsp;
            </li>
            <li>
              Employs such as the COMPUTER-AIDED Radioactive Plaque
              method.&nbsp;
            </li>
          </ul>
        </div>
        <div className="terms-text">
          <h2>
            ALL BRANCHES OF OPHTHALMOLOGY ARE AVAILABLE AT Dünyagöz:
          </h2>
          <ol>
            <li>
              GENERAL EYE EXAMINATION: can help to diagnose and treat many
              life-threatening diseases such as Diabetes, Brain tumors, AIDS,
              MS, Alzheimer's disease, Blood diseases, Heart diseases, Metabolic
              disorders, Parkinson's disease.
            </li>
            <li>
              CATARACT: Cataract surgery with femtosecond laser and cataract
              surgery with the phaco method. Only high quality intraocular lens
              are used.&nbsp;
            </li>
            <li>
              LASER AND INTRAOCULAR LENS: which is applied for the treatment of
              refractive errors such as myopia, hyperopia and astigmatism. The
              newest technology applied in laser treatment which significantly
              reduces the risk of complications:
            </li>
            <ul>
              <ul>
                <li>
                  SMILE (Small Incision Lenticule Extraction) and No-Touch laser
                  technologies,
                </li>
                <li>
                  I-Lasik (Intralase Lasik) an advanced laser method combining
                  the NASA-approved Wavefront and the Intralase method
                  (blade-free laser method)
                </li>
              </ul>
            </ul>
            <ul>
              <li>Bladeless femtosecond laser surgery.</li>
            </ul>
            <ul>
              <ul>
                <li>
                  PRK (photorefractive keratectomy): No scar, the corneal flap
                  is not removed, no destruction of the structure of the cornea,
                  no pain and eye pressure does not increase.&nbsp;
                </li>
              </ul>
            </ul>
            <ul>
              <li>
                Phakic intraocular lens: a Reversible treatment for all
                refraction errors, by placing an artificial lens with diopters
                inside the eye.
              </li>
            </ul>
            <ul>
              <li>
                An Intraocular lens treatment where the lens inside the eye is
                replaced with a lens with more than one focus capability.
              </li>
            </ul>
            <li>
              Presbyopia (near vision impairment) treatment through: Corneal
              Inlay Treatment (Kamra), PresbyLASIK Surgery, Multifocal
              Intraocular Lens Treatment.
            </li>
            <li>
              RETINA diseases: Hemorrhage, Blockage vessels, detachments/tears,
              Macular disease, congenital diseases, Accumulation of fluid,
              edema, Macular perforations, Vitreoretinal superficial disorders,
              and tumors. Treatment methods include Bionic Eye Treatment,
              Macular Degeneration (Yellow Spot), Retina Decollement, Diabetic
              Retinopathy, Retinopathy of Prematurity (ROP).
            </li>
            <li>
              GLAUCOMA (HIGH EYE PRESSURE) for infants and adults throughMedical
              treatment, Laser treatment, and Surgery.
            </li>
            <li>
              PEDIATRIC EYE HEALTH includes treatment of Refractive errors,
              Eyelid diseases, Cataract, Corneal diseases, Glaucoma, Trauma,
              Retinal and optic nerve diseases, Retinopathy of prematurity
              (ROP).
            </li>
            <ul>
              <li>
                Special treatment centers for children equipped with a Playroom{" "}
                <br />
                &bull; Anaesthetist specialised in the anaesthesia of children
              </li>
            </ul>
            <li>
              STRABISMUS is when two eyes look in different directions.
              Treatment: glasses, eye exercises, prismatic glasses, Botox
              injections into the extraocular muscles, or surgery.
            </li>
            <li>
              LAZY EYE (AMBLYOPIA) where the visual capacity of one eye is lower
              than the other eye's or both eyes compared to the normal limits.
            </li>
            <li>
              OCULOPLASTIC and OrbitalSurgery includes a wide variety of
              surgical procedures that deal with the orbit (eye socket),
              eyelids, tear ducts, and the face, such as: the removal of eye
              bags, construction of prosthetic eyes, intraocular tumor
              surgeries, Botox, almond eye, chalazion, plexr, thyroid eye
              disease, small or no eyes as birth defects, Trauma-related
              problems and aesthetic surgery applications.
            </li>
            <li>
              CORNEAL DISEASES suchas Keratoconus, Dry Eyes, Other Corneal
              Diseases, Conjunctiva, Chemical Eye Burns using methods like
              cornea abrasion treatment and corneal transplant.
            </li>
            <li>
              CONTACT LENS where an eye examination is performed by an
              ophthalmologist for the selection of contact lenses suitable for
              the patient's eye structure.
            </li>
            <li>
              NEURO OPHTHALMOLOGY neuro-ophthalmology treats headaches caused by
              eye diseases and deals with common diseases of the eye and nervous
              system, such as MS Multiple Sclerosis, Alzheimer, Parkinson and
              Optic Neuritis
            </li>
            <li>UVEITIS AND EYE INFLAMMATION.</li>
            <li>
              OCULAR ONCOLOGY Ocular oncology: eye tumors and orbit tumors
              (eyelid) is a department that requires two more years of training
              after ophthalmology specialization. Includes: tear system tumor,
              Intraocular tumors (Melanoma), Intraocular tumors
              (Retinoblastoma), Capillary hemangioma, lymphangioma, cavernous
              hemangioma, lymphoma, meningioma, optic glioma, rhabdomyosarcoma.
            </li>
            <li>
              LOW VISION REHABILITATION withOptical or digital low vision aid
              devices (such as telescopic glasses, microscopic glasses,
              telemicroscopes) and/or methods such as microperimetry are used.
            </li>
          </ol>
          <h2>Excellence in Eye Care...</h2>
        </div>
      </div>
    </div>
  );
};

export default DunyagozEn;
