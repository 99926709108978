export const preferenceOptions_ar = [
  {
    id: "q1",
    title: "أرغب بخطّة سياحة علاجيّة ضمن ميزانية محدودة",
    description: "معاملة ميسورة التكلفة مع نفس الخدمة عالية الجودة",
  },
  {
    id: "q2",
    title: "لا يوجد لدي أيّ تفضيلات محددة",
    description: " عقلي مشغول للغاية ، السياحة ليست من أولوياتي في هذا الوقت",
  },
  {
    id: "q3",
    title: "أنا أفضل الشاطئ على رؤية الموقع التاريخي",
    description: "أنطاليا هي الخيار الأمثل لهذا التفضيل",
    additionalLink: { name: "أنطاليا", link: "/cities/antalya" },
  },
  {
    id: "q4",
    title: "أنا أفضل رؤية الموقع التاريخي على الشاطئ",
    description: "اسطنبول هي الخيار الأمثل لهذا التفضيل",
    additionalLink: { name: "اسطنبول", link: "/cities/istanbul" },
  },
  {
    id: "q5",
    title: "أفضّل القرب من مراكز التسوق",
    description:
      "إذا كنت تخطط للقيام بنفس التسوق ؛ الاستفادة من أسعار الصرف والمنتجات عالية الجودة",
  },
  {
    id: "q6",
    title: "أفضّل القرب من المطارات",
    description:
      "سرعة الدخول والخروج دون القلق بشأن التأخيرات المرورية وحشود السائحين",
  },
];
