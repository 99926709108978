const onHandleMobileMenu = (setHide) => {
    setHide(false);
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
};

const MenuButton = ({ setHide }) => {
    return (
        <a href="#" id="mobile_btn" onClick={() => onHandleMobileMenu(setHide)}>
            <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </a>
    )
}

export default MenuButton;