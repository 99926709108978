import format from "date-fns/format";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AlertDismissible from "../../../common/components/AlertDismissible";
import servive1Banner from "../../assets/images/service1.jpg";
import { useTranslation } from "react-i18next";

const TreatmentSuccess = () => {
  const location = useLocation();
  const [t] = useTranslation();
  return (
    <div className="content success-page-cont">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            {/* Success Card */}
            <div className="card success-card">
              <div className="card-body">
                <div className="success-cont">
                  <i className="fas fa-check"></i>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${t(
                        "Treatment.SuccessfullyAddedYourTreatment"
                      )}`,
                    }}
                  ></p>
                  {/* <h4 className="my-5">
                    {t("Appointmentbooked")}#{" "}
                    <strong>{location?.state?.appointmentId}</strong>
                  </h4> */}
                  <a href="/" className="btn btn-primary view-inv-btn">
                    {t("BackToHome")}
                  </a>
                </div>
              </div>
            </div>
            {/* /Success Card */}
          </div>
        </div>
      </div>
    </div>
  );
};
const TravelSuccess = () => {
  const location = useLocation();
  return (
    <div className="content success-page-cont">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            {/* Success Card */}
            <div className="card success-card">
              <div className="card-body">
                <div className="success-cont">
                  <i className="fas fa-check"></i>
                  <h3>Successfully Added Your Travel Preferences!</h3>
                  <p>
                    Appointment booked with id#{" "}
                    <strong>{location?.state?.appointmentId}</strong>
                  </p>
                  <a href="/" className="btn btn-primary view-inv-btn">
                    Back to Home
                  </a>
                </div>
              </div>
            </div>
            {/* /Success Card */}
          </div>
        </div>
      </div>
    </div>
  );
};
const MedicalSuccess = () => {
  const location = useLocation();
  console.log(`SUCCESS :: location : ${JSON.stringify(location)}`);
  return (
    <>
      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {!!location?.state?.values?.doctorData?.doc_name &&
              !!location?.state?.values?.appointmentDate ? (
                <AlertDismissible
                  type={"success"}
                  boldMsg={"Appointment booked Successfully!"}
                  msg={
                    <>
                      <p className="mt-2 mb-1">
                        Appointment booked with{" "}
                        <strong>{`${location?.state?.values?.doctorData?.doc_name}`}</strong>{" "}
                        on{" "}
                        <strong>{`${
                          !!location?.state?.values?.appointmentDate &&
                          format(
                            new Date(location?.state?.values?.appointmentDate),
                            "dd/MM/yyyy"
                          )
                        }`}</strong>
                      </p>
                      <p className="mt-2 mb-1">
                        <strong>Booking Number: </strong>
                        {location?.state?.appointmentId}
                      </p>
                    </>
                  }
                />
              ) : (
                ""
              )}

              <div className="card text-center">
                <h2 className="mt-5">
                  You may also like to enjoy our extra services...
                </h2>
                <div className="card-body">
                  <p className="card-text">
                    <Link
                      to="/services-survey"
                      state={{ appointmentId: location?.state?.appointmentId }}
                      className="btn btn-primary"
                    >
                      Sure! Show Me!
                    </Link>
                  </p>
                  <img
                    src={servive1Banner}
                    className="img-fluid p-5"
                    alt="Your Medical Portal Login"
                  />
                </div>
                <Link to="/" className="mb-5">
                  Maybe later, take me home for now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { TravelSuccess, MedicalSuccess, TreatmentSuccess };
