const MenuCloseButton = ({ onClick }) => {
    return (
        <a
            href="#"
            id="menu_close"
            className="menu-close"
            onClick={() => onClick()}
        >
            <i className="fas fa-times"></i>
        </a>
    )
}

export default MenuCloseButton;