import { APIFilesRequest, APIRequest } from "common";


const getAllAppointments = (userId) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment${!!userId ? `/user/${userId}` : ""
    }`;

  return APIRequest({
    url: url,
    queryParams: { size: 500, page: 0 },
    method: "get",
  });
};

const getAppointmentById = (id) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment/${id}`;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const updateAppointment = (appointmentId, att) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment/${appointmentId}`;

  return APIRequest({
    url: url,
    method: "put",
    requestBody: { ...att },
  });
};

const createAppointment = (userId, doctorId, appointmentDate, status, lang) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment`;

  return APIRequest({
    url: url,
    method: "post",
    requestBody: {
      userId,
      doctorId,
      appointmentDate,
      status,
      lang: lang
    },
  });
};

const createAppointmentWithFiles = (request) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment`;

  return APIRequest({
    url: url,
    method: "post",
    requestBody: request,
  });
};

const createAppointmentWithoutFiles = (request) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment`;

  return APIRequest({
    url: url,
    method: "post",
    requestBody: request,
  });
};

const exportAppointmentDetails = (id) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment/export/details/${id}`;
  return APIFilesRequest({
    url: url,
    method: "get",
  });
};
const exportTravelPreferences = (id) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/appointment/export/travel-preferences/${id}`;
  return APIFilesRequest({
    url: url,
    method: "get",
  });
};
const AppointmentService = {
  createAppointment,
  createAppointmentWithFiles,
  getAllAppointments,
  updateAppointment,
  getAppointmentById,
  exportAppointmentDetails,
  exportTravelPreferences,
  createAppointmentWithoutFiles
};

export default AppointmentService;
