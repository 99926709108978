import { APIFilesRequest } from "common";

const getAttachmentById = (attachmentId) => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/attachment/${attachmentId}`;
  return APIFilesRequest({
    url: url,
    method: "get",
  });
};

const AttachmentService = {
  getAttachmentById,
};

export default AttachmentService;
