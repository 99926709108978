import { createContext, useState } from "react";

const TreatmentsContext = createContext({
    data: {},
    updateData: () => {},
});

export const TreatmentsContextProvider = (props) => {
    const [data, setData] = useState(null);

    const updateData = (data) => {
        console.log('setting treatments data', data);
        setData(data);
    }

    const contextValue = {
        updateData: updateData,
        data: data,
    }

    return (
        <TreatmentsContext.Provider value={contextValue}>
            {props.children}
        </TreatmentsContext.Provider>
    );
};

export default TreatmentsContext;