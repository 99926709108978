import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container, Navbar } from "react-bootstrap";
import AppContext from "store/app-store";

const menuItems = [
  { path: "about-us", textId: "Header.AboutUs" },
  {
    path: "medical-tourism",
    textId: "Header.Medical-Tourism",
    subItems: [
      {
        path: "medical-tourism/why-medical-tourism",
        textId: "Header.Why-Medical-Tourism",
      },
      {
        path: "medical-tourism/is-it-right-for-me",
        textId: "Header.Is-it right-for-me",
      },
      {
        path: "medical-tourism/essentials-to-pack",
        textId: "Header.Essentials-to-pack",
      },
    ],
  },
  {
    path: "turkey",
    textId: "Header.Turkey",
    subItems: [
      { path: "turkey/about-turkey", textId: "Header.AboutTurkey" },
      { path: "turkey/why-turkey", textId: "Header.WhyTurkey" },
    ],
  },
  // { path: "treatments", textId: "Treatments" },
  {
    path: "cities",
    textId: "Header.Cities",
    subItems: [
      { path: "cities/istanbul", textId: "Header.Cities-Istanbul" },
      { path: "cities/antalya", textId: "Header.Cities-Antalya" },
    ],
  },
  { path: "health-providers", textId: "Header.HealthProviders" },
  { path: "faq", textId: "Header.FAQ" },
  { path: "what-to-expect", textId: "Header.WhatToExpect" },
  // { path: 'terms-and-conditions', textId: 'Header.TermsAndConditions' },
  // { path: 'privacy-policy', textId: 'Header.PrivacyPolicy' },
];

const getMenuItem = (
  currentLang,
  { path, textId, subItems },
  pathname,
  onClick,
  idx,
  translate
) => {
  return (
    <React.Fragment key={idx}>
      {subItems && (
        <NavDropdown title={translate(textId)}>
          {subItems.map(({ path: subPath, textId: subItemLabel }, subIdx) => (
            <NavDropdown.Item
              key={`sub-${subIdx}`}
              href={`/${currentLang}/${subPath}`}
              style={{ textAlign: "start" }}
            >
              {translate(subItemLabel)}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      )}
      {!subItems && (
        <Nav.Link href={`/${currentLang}/${path}`} className="px-lg-4">
          {translate(textId)}
        </Nav.Link>
      )}
    </React.Fragment>
  );
};

const MenuPages = ({ onClick, isLoggedIn, onLogout }) => {
  const [t] = useTranslation();
  const { getLang } = useContext(AppContext);
  let pathname = window.location.pathname;

  const populateMenu = (currentLang) => {
    return menuItems.map((menuItem, idx) =>
      getMenuItem(currentLang, menuItem, pathname, onClick, idx, t)
    );
  };

  //    const getLoginSignupButton = (isLoggedIn) => {
  //        return isLoggedIn ? (
  //            // <li className="login-link" onClick={() => onLogout()}>
  //            //     <Link to="/" onClick={() => onClick()}>
  //            //         {t("Logout")}
  //            //     </Link>
  //            // </li>
  //            <Nav.Link href={'/'}>{t("Logout")}</Nav.Link>
  //        ) : (
  //            // <li className={`login-link ${pathname === "/login" ? "active" : ""}`} onClick={() => onClick()}>
  //            //     <Link to="login" onClick={() => onClick()}>
  //            //         {t("Login")} / {t("Signup")}
  //            //     </Link>
  //            // </li>
  //            <Nav.Link className={`login-link ${pathname === "/login" ? "active" : ""}`} href={'/login'}>{t("Login")} / {t("Signup")}</Nav.Link>
  //        )
  //    };

  return (
    <Navbar expand="lg" expanded>
      <Nav className="me-auto main-nav" style={{ color: "#fff" }}>
        {populateMenu(getLang())}
        {/* { getLoginSignupButton() } */}
      </Nav>
    </Navbar>
  );
};

export default MenuPages;
