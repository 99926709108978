import { Helmet } from "react-helmet";

function MetaTags({ title, tags }) {
    return (
        <Helmet>
            <title>{title}</title>
            {tags.map((tag, index) => (
                <meta key={index} name={tag.name} content={tag.content} />
            ))}
        </Helmet>
    );
}

export default MetaTags;
