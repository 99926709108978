import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthService } from "client/services";
import ClientRoutes from "client/routes";
import AdminRoutes from "admin/routes";
import { Header, Content, Footer, ScrollToTop } from "common/components";
import CommonRoutes from "common/routes";
import AppContext from "store/app-store";
import Theme from "./theme";
import "./App.css";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { DEFAULT_LANG } from "i18n";

const ComingSoon = React.lazy(() => import("./client/Pages/ComingSoonPage"));

const updateDocDir = (lang) => {
  document.documentElement.lang = lang;
  document.body.setAttribute("dir", lang === "ar" ? "rtl" : "ltr");
  document.body.parentNode.setAttribute("dir", lang === "ar" ? "rtl" : "ltr");
  document.body.setAttribute(
    "style",
    lang === "ar" ? "text-align:right" : "text-align:left"
  );
};

const AppRouter = (props) => {
  const user = AuthService.getCurrentUser();
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [lang, setLang] = useState(
    localStorage.getItem("i18nextLng")?.split("-")[0] || DEFAULT_LANG
  );
  const { getLang } = useContext(AppContext);
  const propertyId = process.env.REACT_APP_TAWK_PROPERTY_ID;
  const [tawkId, setTawkId] = useState(
    localStorage.getItem("i18nextLng") === "ar"
      ? process.env.REACT_APP_TAWK_ID_AR
      : process.env.REACT_APP_TAWK_ID
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_CHATBOT === "true") {
      window.addEventListener("storage", () => {
        onLoad();
        onBeforeLoad();
        onBeforeLoaded();
        setLang(localStorage.getItem("i18nextLng")?.split("-")[0] || DEFAULT_LANG);
      });
    }

    setIsLoggedIn(!!user);
    updateDocDir(getLang());
  }, [user, isLoggedIn, getLang, propertyId, tawkId, lang]);

  const pathnames = window.location.pathname;
  const url = pathnames.split("/")[1];

  const tawkMessengerRef = useRef();
  const onLoad = () => {
    setTawkId(
      localStorage.getItem("i18nextLng") === "ar"
        ? process.env.REACT_APP_TAWK_ID_AR
        : process.env.REACT_APP_TAWK_ID
    );
  };
  const onBeforeLoad = () => {
    setTawkId(
      localStorage.getItem("i18nextLng") === "ar"
        ? process.env.REACT_APP_TAWK_ID_AR
        : process.env.REACT_APP_TAWK_ID
    );
  };
  const onBeforeLoaded = () => {
    setTawkId(
      localStorage.getItem("i18nextLng") === "ar"
        ? process.env.REACT_APP_TAWK_ID_AR
        : process.env.REACT_APP_TAWK_ID
    );
  };

  return (
    <Theme lang={getLang()}>
      <TawkMessengerReact
        propertyId={propertyId}
        widgetId={tawkId}
        onBeforeLoad={onBeforeLoad}
        onBeforeLoaded={onBeforeLoaded}
        onLoad={onLoad}
        ref={tawkMessengerRef}
      />
      <BrowserRouter>
        <ScrollToTop />
        <>
          <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          <Content>
            <Routes>
              <Route exact path="/*">
                <Route exact path=":lang/*">
                  {ClientRoutes(lang, setIsLoggedIn)}
                  {AdminRoutes(lang, isLoggedIn, user)}
                  {CommonRoutes(lang)}
                </Route>
                <Route
                  path="*"
                  element={<Navigate to={`/${lang}`} replace />}
                />
              </Route>
            </Routes>
          </Content>
          {!url.includes("account") ? <Footer /> : ""}
        </>
      </BrowserRouter>
    </Theme>
  );
};

export default AppRouter;
