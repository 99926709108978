import { Error } from "common/components";

const InputField = ({ input, meta, ...props }) => {
  return (
    <>
      {props.label_type === "form_label" ? <label>{props.label}</label> : ""}
      {input.type === "text" ||
      input.type === "radio" ||
      input.type === "email" ||
      input.type === "date" ||
      input.type === "password" ||
      input.type === "file" ? (
        <input {...props} {...input} />
      ) : input.type === "textarea" ? (
        <textarea {...props} {...input} />
      ) : (
        ""
      )}{" "}
      {input.type === "radio" ? props.label : ""}
      {props.label_type === "focus_label" ? (
        <label className="focus-label">{props.label}</label>
      ) : (
        ""
      )}
      {!!!props.hideerror && (
        <Error name={input.name} showerrorbeside={!!!input.showerrorbeside} />
      )}
    </>
  );
};

export default InputField;