

import React from "react";
import { Navigate, Route } from "react-router-dom";

const Specialities_Admin = React.lazy(() => import("../Pages/SpecialitiesPage"));
const Users_Admin = React.lazy(() => import("../Pages/UsersPage"));
const Doctors_Admin = React.lazy(() => import("../Pages/DoctorsPage"));
const Countries_Admin = React.lazy(() => import("../Pages/CountriesPage"));
const Cities_Admin = React.lazy(() => import("../Pages/CitiesPage"));
const Facilities_Admin = React.lazy(() => import("../Pages/FacilitiesPage"));
const Treatments_Admin = React.lazy(() => import("../Pages/TreatmentsPage"));

const AdminRoutes = (currentLang, isLoggedIn, user) => 
    isLoggedIn &&
    user?.roles?.length > 0 &&
    user?.roles.filter((u) => u === "ROLE_ADMIN")?.length > 0 && [
        <Route path="admin">
            <Route path="Specialities" element={<Specialities_Admin />} />
            <Route path="Users" element={<Users_Admin />} />
            <Route path="Doctors" element={<Doctors_Admin />} />
            <Route path="Countries" element={<Countries_Admin />} />
            <Route path="Cities" element={<Cities_Admin />} />
            <Route path="Facilities" element={<Facilities_Admin />} />
            <Route path="Treatments" element={<Treatments_Admin />} />
            <Route path="*" element={<Navigate to={`/${currentLang}`} replace />} />
        </Route>
    ];

export default AdminRoutes;