import logo from "client/assets/images/footer-logo.webp";
import facebookIcon from "client/assets/images/social-media/facebook logo_icon.png";
import instagramIcon from "client/assets/images/social-media/instagram logo_icon.png";
import youtubeIcon from "client/assets/images/social-media/youtube logo_icon.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const [t, i18n] = useTranslation();
  const exclusionArray = [
    "/pages/doctor-grid",
    "/pages/doctor-list",
    "/pages/video-call",
    "/pages/voice-call",
    "/pages/chat-doctor",
    "/patient/doctor-list",
    "/patient/doctor-grid",
  ];

  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return null;
  }

  return (
    <footer className="footer" id="footer-home">
      {/* Footer Bottom */}
      <div className="footer-bottom" id="flex-footer-container">
        {/* Copyright */}
        <div className="flex-footer-left">
          <div className="copyright-menu">
            <ul className="logo-copyright-menu">
              <li>
                <img
                  src={logo}
                  alt=""
                  style={{ width: "7rem" }}
                  className="mt-0"
                />
                <br />
                <span className="m-3">{t("Footer.powered_by")}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-footer-center text-white">
          <div className="social-icon">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/yourmedicalportal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={facebookIcon}
                    alt="facebook icon"
                    className="social-icon-item"
                  />
                </a>
              </li>
              <li>
                <a
                  href={
                    i18n.language === "ar"
                      ? "https://www.instagram.com/your_medical_portal_arabic"
                      : "https://www.instagram.com/your_medical_portal_turkiye"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={instagramIcon}
                    alt="instagram icon"
                    className="social-icon-item"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@yourmedicalportal8142"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={youtubeIcon}
                    alt="youtube icon"
                    className="social-icon-item"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="px-2 d-none d-md-block">
            {t("Footer.certified_Providers")}
          </div>
          <div className="px-2">
            <div className=" d-none d-md-block" style={{ textAlign: "center" }}>
              {t("Footer.transparent_process")}
            </div>
            <br />
            <div className="footer-social-media" hidden>
              <a
                href="https://www.facebook.com/yourmedicalportal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookIcon} alt="facebook icon" />
              </a>
              <a
                href={
                  i18n.language === "ar"
                    ? "https://www.instagram.com/your_medical_portal_arabic"
                    : "https://www.instagram.com/your_medical_portal_turkiye"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagramIcon} alt="instagram icon" />
              </a>
              <a
                href="https://youtube.com/@yourmedicalportal8142"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtubeIcon} alt="youtube icon" />
              </a>
            </div>
          </div>
          <div className="px-2 d-none d-md-block">
            {t("Footer.experienced_guidance")}
          </div>
        </div>
        <div className="flex-footer-right">
          {/* Copyright Menu */}
          <div className="copyright-menu">
            <ul className="policy-menu">
              {/* <li>
                <Link to="/about-us"> {t("Header.AboutUs")}</Link>
              </li> */}
              <li>
                <Link to={`${i18n.language}/terms-and-conditions`}>
                  {t("Header.TermsAndConditions")}
                </Link>
              </li>
              <li>
                <Link to={`${i18n.language}/privacy-policy`}>
                  {t("Header.PrivacyPolicy")}
                </Link>
              </li>
            </ul>
          </div>
          {/* /Copyright Menu */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default Footer;
