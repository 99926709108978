import React from "react";
import { Form } from "react-final-form";
import { Step, StepLabel, Stepper } from "@mui/material";
import PropTypes from "prop-types";
import defaultBanner from "client/assets/images/login-banner.png";

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: props?.initialValues?.page ? props?.initialValues?.page : 0,
      values: props.initialValues || {},
    };
  }

  componentDidMount() {
    document.body.classList.add("account-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("account-page");
  }

  next = (values) => 
    this.setState((state) => ({
      ...values,
      page: Math.min(state.page + 1, this.props.children.length - 1),
    }));

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage?.props.validate ? activePage?.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      this.next(values);
    }
  };

  getChildren = (children) => {
    return Array.isArray(children) ? children?.filter((c) => c) : children;
  };

  getLabel = (child) => {
    if(child.props?.label?.ar?.length > 0) {
      return child.props?.label?.ar
    }
    return child.props.label
  }

  render() {
    const { children, labels } = this.props;
    const { page, values } = this.state;

    const childrenArray = React.Children.toArray(children);
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-4">
              <div className="account-content">
                <div className="row align-items-center justify-content-center">
                  <div className="col-sm-12 col-md-5 login-left">
                    <img
                      src={activePage?.props?.serviceBanner || defaultBanner}
                      className="img-fluid "
                      alt="Treatments Wizard"
                    />
                  </div>
                  <div
                    className="col-sm-12 col-md-7 login-right card-body"
                    style={{ minHeight: "40px" }}
                  >
                    <Form
                      initialValues={values}
                      validate={(values) => this.validate(values)}
                      onSubmit={this.handleSubmit}>
                      {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit} className="needs-validation">
                          <Stepper alternativeLabel activeStep={page}>
                            {childrenArray.length > 1 &&
                              childrenArray.map((child, index) => (
                                <Step key={index} completed={page > index}>
                                  <StepLabel>
                                    {this.getLabel(child)}
                                  </StepLabel>
                                </Step>
                            ))}
                          </Stepper>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card mt-3">
                                <div className="card-body">{activePage}</div>
                              </div>
                            </div>
                          </div>
                          <div className="submit-section d-flex justify-content-around">
                            {page > 0 && (
                              <button
                                className="btn btn-primary submit-btn"
                                type="button"
                                onClick={this.previous}
                                disabled={submitting}
                              >
                                {this.props?.lables?.previous}
                              </button>
                            )}
                            {!isLastPage && page >= 0 && (
                              <button
                                className="btn btn-primary submit-btn"
                                type="submit"
                              >
                                {this.props?.lables?.next}
                              </button>
                            )}
                            {isLastPage && (
                              <button
                                className="btn btn-primary submit-btn"
                                type="submit"
                                disabled={submitting}
                              >
                                {this.props?.lables?.summary}
                                {submitting && (
                                  <span className="spinner-border spinner-border-sm mb-1 mx-1"></span>
                                )}
                              </button>
                            )}
                          </div>

                          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                        </form>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
