import React from "react";
import { essentials_to_pack_01 } from "../img";
import { essentials_to_pack_02 } from "../img";
import { renderDynamicHeading } from "common/helpers";
import { useLocation } from "react-router-dom";
import { providersList_ar } from "../health-providers/ProvidersList_ar";

const DunyagozAr = () => {
  const location = useLocation();
  return (
    <div className="terms-content">
      <div className="terms-text">
        <h1>Dünyagöz</h1>
        <h2>إجمالي 4.7 نجمة من أصل 8043 تقييم من Google </h2>
        <p>
          استحدثت مجموعة مستشفيات Dünyagöz مفهوم مرافق العلاج المتخصص في تركيا
          في عام 1996 ، ومن حينها تقدم مئات من طرق العلاج المختلفة في جميع فروع
          طب العيون.
        </p>
        <p>
          Dünyagöz موجودة في 21 موقعًا في تركيا و 9 مواقع خارجها ، وقد وصلت إلى
          قدرة يومية تصل إلى 8000 علاج في العيادات و 1000 تدخل جراحي.
        </p>
        <p>
          يوجد في Dünyagöz طاقم طبي مكون من 300 طبيب متخصص وأكاديمي &ndash;
          أساتذة وأساتذة مساعدين ، وطاقم دعم يبلغ 3500.
        </p>
        <p>تقدم Dünyagöz كل عام خدماتها لحوالي 110.000 مريض من 160 دولة.</p>
        <ul>
          <li>
            المستشفى معتمد من قبل اللجنة المشتركة الدولية JCI التابعة لأكبر
            منظمة اعتماد في العالم.
          </li>
          <li>
            ISO 9001 (المنظمة الدولية للتوحيد القياسي) : يتم تدقيقها كل عام
            بواسطة T&Uuml;V-S&Uuml;D .
          </li>
          <li>نظام ISO 10002 الذي يختص في رضى المرضى.</li>
        </ul>

        {/* Certifications - Start */}
        {renderDynamicHeading(
          providersList_ar,
          "certifications",
          "h2",
          "الشهادات",
          location
        )}
        {/* Certifications - End */}

        {/* excellence_areas - Start */}
        {renderDynamicHeading(
          providersList_ar,
          "excellence_areas",
          "h2",
          "مجالات التميز",
          location
        )}
        {/* excellence_areas - End */}

        <h2>المؤهلات</h2>
        <ul>
          <li>
            طاقم طبي ذو خبرة من الأكاديميين - الأساتذة والأساتذة المساعدين -
            والمتخصصين المحترفين مع خبرة من آلاف العمليات الجراحية ،
          </li>
          <li>
            اختيار متخصص لأحدث التقنيات ذات القياس الذهبي ، المعتمدة من قبل
            السلطات المعترف بها دوليًا وأطباء العيون ذوي الخبرة ،
          </li>
          <li>أكثر التقنيات أمانًا وتطورًا ،</li>
          <li>
            استخدام المواد الاستهلاكية المعتمدة من إدارة الغذاء والدواء فقط في
            الجراحة ،
          </li>
          <li>
            مرافق مجهزة بشكل شامل لتقديم جميع خدمات التشخيص والعلاج تحت سقف واحد
            على مدار الساعة (24/7) ،
          </li>
          <li>
            أول من ينفذ أكبر مشروع المسئولية الاجتماعية في طب العيون: عيادة
            العيون المتنقلة.
          </li>
          <li>
            مركز جراحة محجر العين: المرفق الأول والوحيد في العالم لتشخيص وعلاج
            أورام العين الداخلية ،
          </li>
          <li>
            المشاركة المستمرة في الأحداث المهنية الدولية ، ومواكبة أحدث إجراءات
            وتقنيات العلاج.
          </li>
          <li>
            إدخال أفضل معدات التشخيص والعلاج وأكثرها أمانًا في تركيا مثل
            Femtosecond-Laser وعدسات العين الداخلية متعددة البؤر المعتمدة من
            إدارة الغذاء والدواء.
          </li>
          <li>
            استخدام المستهلكات الفردية لضمان ظروف صحية على أعلى مستوى ، تحت أي
            ظرف من الظروف لا يتم استخدام المواد التي يمكن التخلص منها أكثر من
            مرة.
          </li>
          <li>
            حلول رعاية صحية 360 درجة مثل النصائح الغذائية لمرضى اعتلال الشبكية
            السكري.
          </li>
          <li>
            المنهج الشامل: يتم فحص المرضى بشكل شامل بالتعاون مع أطباء من مختلف
            الفروع.
          </li>
          <li>
            أول مقدم رعاية صحية يطبق بنجاح العيون الالكترونية " II Retinal
            Implant" في تركيا.
          </li>
          <li>
            يستخدم المستشفى عدسات ذكية ثلاثية البؤرة داخل العين تم تطويرها على
            مدى فترة من 6 إلى 7 سنوات بميزانية بحث وتطوير تبلغ ما يقرب من 5
            مليارات دولار أمريكي ، والتي أثبتت نجاحها من خلال فعاليتها في العلاج
            من عملية جراحية واحدة.
          </li>
          <li> إنشاء أول مركز لتشخيص وعلاج القرنية المخروطية في تركيا.</li>
          <li>
            أول مقدم خدمة صحية في تركيا يقدم علاج الساد بتقنية الفيمتو ليزر
            المبتكرة ، في نفس الوقت الذي تم فيه تقديم هذه الطريقة المبتكرة في
            أوروبا.
          </li>
          <li>
            تستغرق قطرات التخدير دقائق فقط حتى يتمكن المرضى من العودة إلى روتين
            حياتهم اليومية بشكل أسرع.
          </li>
          <li>يدير المستشفى مركز أورام العين الأفضل تجهيزًا في تركيا.</li>
        </ul>
        <h2> جميع فروع طب العيون متوفرة في Dünyagöz:</h2>
        <ol>
          <li>
            الفحص العام للعين: يمكن أن يساعد في تشخيص وعلاج العديد من الأمراض
            التي تهدد الحياة مثل مرض السكري وأورام الدماغ والإيدز والتصلب
            المتعدد ومرض الزهايمر وأمراض الدم وأمراض القلب والاضطرابات الأيضية
            ومرض باركنسون.
          </li>
          <li>
            كاتاراكت: جراحة الساد باستخدام ليزر Femtosecond وجراحة الساد بطريقة
            الفاكو. يتم استخدام عدسة باطن العين عالية الجودة فقط.
          </li>
          <li>
            عدسات الليزر وداخل العين: وهي تستخدم في معالجة الأخطاء الانكسارية
            مثل قصر النظر ومد البصر والاستجماتيزم. أحدث التقنيات المطبقة في
            العلاج بالليزر والتي تقلل بشكل كبير من خطر حدوث مضاعفات:
          </li>
          <ul>
            <li>SMILE (استخراج العدس بشق صغير) وتقنيات الليزر بدون لمس ،</li>
            <li>
              I-Lasik (Intralase Lasik) : طريقة ليزر متقدمة تجمع بين طريقة
              Wavefront المعتمدة من وكالة ناسا وطريقة Intralase (طريقة الليزر
              الخالية من الشفرات)،
            </li>
            <li> جراحة ليزر femtosecond بدون شفرات.</li>
            <li>
              PRK (استئصال القرنية الانكساري للضوء): لا يوجد ندبة ، لا يتم إزالة
              سديلة القرنية ، لا يوجد تدمير لبنية القرنية ، لا يوجد ألم ولا يزيد
              ضغط العين.
            </li>
            <li>
              عدسة Phakic داخل العين: علاج قابل للعكس لجميع أخطاء الانكسار عن
              طريق وضع عدسة صناعية داخل العين.
            </li>
            <li>
              علاج العدسة داخل مقلة العين حيث يتم استبدال العدسة الموجودة داخل
              العين بعدسة ذات أكثر من قدرة تركيز بؤري.
            </li>
          </ul>
          <li>
            علاج قصر النظر (ضعف البصر القريب) من خلال: علاج ترصيع القرنية
            (كامرا) وجراحة بريسبي ليزك وعلاج العدسة داخل العين متعدد البؤر.
          </li>
          <li>
            أمراض الشبكية: نزيف ، انسداد الأوعية ، انفصال / تمزق ، مرض بقعي ،
            أمراض خلقية ، تراكم السوائل ، وذمة ، انثقاب بقعي، اضطرابات سطحية
            شبكية وجسمية ، وأورام. تشمل طرق العلاج علاج العين الإلكترونية ،
            التنكس البقعي (البقعة الصفراء) ، إزالة الشبكية ، اعتلال الشبكية
            السكري ، اعتلال الشبكية الخداجي ROP.
          </li>
          <li>
            الجلوكوما (ارتفاع ضغط العين) للرضع والبالغين من خلال العلاج الطبي
            والعلاج بالليزر والجراحة.
          </li>
          <li>
            صحة عيون الأطفال تشمل علاج الأخطاء الانكسارية وأمراض الجفن وإعتام
            عدسة العين وأمراض القرنية والزرق والصدمات وأمراض الشبكية والعصب
            البصري واعتلال الشبكية الخداجي،
          </li>
          <ul>
            <li>مراكز علاج خاصة للأطفال مجهزة بغرفة ألعاب.</li>
          </ul>
          <ul>
            <li>طبيب تخدير متخصص في تخدير الأطفال.</li>
          </ul>
          <li>
            الحَوَل STRABISMUS هو عندما تنظر العينان في اتجاهين مختلفين. العلاج:
            النظارات ، أو تمارين العين ، أو النظارات المنشورية ، أو حقن البوتوكس
            في عضلات العين ، أو الجراحة.
          </li>
          <li>
            العين الكسولة (AMBLYOPIA) حيث تكون القدرة البصرية لعين واحدة أقل من
            العين الأخرى أو كلتا العينين مقارنة بالحدود الطبيعية.
          </li>
          <li>
            جراحة منطقة العين OCULOPLASTIC و Orbital تشمل مجموعة واسعة من
            العمليات الجراحية التي تتعامل مع محجر العين والجفون والقنوات الدمعية
            والوجه ، مثل: إزالة أكياس العين ، وبناء العيون الاصطناعية ، وجراحات
            الأورام داخل العين ، البوتوكس ، عين اللوز ، البردة ، plexr ، مرض عين
            الغدة الدرقية ، عيون صغيرة أو معدومة كعيوب خلقية ، مشاكل متعلقة
            بالصدمات وتطبيقات الجراحة التجميلية.
          </li>
          <li>
            أمراض القرنية مثل القرنية المخروطية وجفاف العيون وأمراض القرنية
            الأخرى والملتحمة وحروق العين الكيميائية باستخدام طرق مثل علاج تآكل
            القرنية وزرع القرنية.
          </li>
          <li>
            العدسات اللاصقة حيث يتم إجراء فحص العين من قبل طبيب عيون لاختيار
            العدسات اللاصقة المناسبة لبنية عين المريض.
          </li>
          <li>
            طب العيون العصبي يعالج الصداع الناتج عن أمراض العيون ويتعامل مع
            الأمراض الشائعة في العين والجهاز العصبي ، مثل التصلب المتعدد ،
            الزهايمر ، باركنسون والتهاب العصب البصري
          </li>
          <li>التهابات العين.</li>
          <li>
            طب أورام العين: أورام العين وأورام الجفن هو قسم يتطلب سنتين إضافيتين
            من التدريب بعد تخصص طب العيون، يشمل: ورم الجهاز الدمعي ، أورام باطن
            العين (الورم الميلاني) ، أورام باطن العين (الورم الأرومي الشبكي) ،
            ورم وعائي شعري ، ورم وعائي لمفي ، ورم وعائي كهفي ، ورم ليمفوي ، ورم
            سحائي ، ورم دبقي بصري ، ورم عضلي عضلي.
          </li>
          <li>
            إعادة تأهيل الرؤية المنخفضة باستخدام أجهزة المساعدة البصرية أو
            الرقمية لضعف الرؤية (مثل النظارات التلسكوبية والنظارات المجهرية
            والميكروسكوبات عن بُعد) وغيرها.
          </li>
        </ol>
        <h2>التميز في العناية بالعيون ...</h2>
      </div>
    </div>
  );
};

export default DunyagozAr;
