import React from "react";
import { Link, useLocation } from "react-router-dom";
import { is_it_right_for_me } from "../img";
import { providersList_en } from "../health-providers/ProvidersList_en";
import { renderDynamicHeading } from "common/helpers";

const EstethicaEn = () => {
  const location = useLocation();
  return (
    <div className="terms-content">
      <div className="terms-text">
        <h1>Estethica</h1>
        <h2>A total of 4.8 stars out of 5991 google reviews.</h2>
        <div className="terms-text">
          <h3>Quality</h3>
          <p>
            Estethica Health Group is a leading health institution in
            T&uuml;rkiye with its high-quality aesthetic services, thanks to
            combinng state-of-art methods and equipment with ethical principles.
          </p>
          <p>
            The clinic owns a patent for creating the DHI Plus Gold hair
            transplantation technique.
          </p>

          {/* Certifications - Start */}
          {renderDynamicHeading(
            providersList_en,
            "certifications",
            "h3",
            "Certifications",
            location
          )}
          {/* Certifications - End */}

          {/* excellence_areas - Start */}
          {renderDynamicHeading(
            providersList_en,
            "excellence_areas",
            "h3",
            "Areas of Excellence",
            location
          )}
          {/* excellence_areas - End */}
        </div>

        <div className="terms-text">
          <h3>Overview</h3>
          <p>
            Estethica's 240 qualified medical staff members and surgeons have
            gained international experience and reputation, the vast majority of
            which are members of the Turkish Association of Reconstructive,
            Plastic and Aesthetic Surgery.
          </p>
          <p>
            With &ldquo;Healthy beauty&rdquo; motto, Estethica conducts more
            than 2,500 hair transplants per year.&nbsp;
          </p>
          <p>
            In the clinic, special attention is paid to preoperative preparation
            of the patient, as well as to monitoring his condition after the
            operation.&nbsp;
          </p>
          <p>
            Aesthetics clinics regularly conduct "Patient Satisfaction
            Assessment" to meet the highest requirements of their guests.
          </p>
          <p>
            High diagnostic and treatment standards are guaranteed at Estethica:
            You will receive a guarantee certificate for your treatment result.
          </p>
          <h3>Building and Rooms</h3>
          <p>
            The hospitals are designed to create a comfortable and relaxing
            atmosphere, with private rooms that are contemporary furnished,
            equipped with internet and designed with 2 floors for the comfort of
            the patient and companions.
          </p>
          <p>
            Clinic Estethica Atashehir is located in an 8-storey building, with
            an internal area of 6,000 square meters, encompassing 12 modern
            operating rooms.
          </p>
          <h3>For your Comfort</h3>
          <p>
            A SPA and Wellness clinic is available for patients where they can
            pay attention to their overall health under medical supervision.{" "}
            <br />
            On an ongoing basis, Estethica conducts a survey of patients about
            their satisfaction with the quality of the center's services.&nbsp;
          </p>
        </div>
        <h2>Treatments</h2>
        <div className="terms-text">
          <h3>
            Dermatology, Medical Aesthetics and Reconstructive Surgery&nbsp;
          </h3>
          <p>
            Highly experienced doctors - modern treatment methods - minimally
            invasive (least traumatic) surgery methods - new-generation
            equipment - safe environment - medically certified imported
            products.
          </p>
          <p>
            Innovative stem cell therapy is used to treat: dermatological
            problems, hair loss, burns and wounds, and wrinkles.
          </p>
          <p>
            Skin care treatments include: Classic Skincare, Anti-ageing
            Treatments, Collagen Care, Gold Care&nbsp;(24 carat pure gold),
            Blemish Treatment, Acne Treatment, Chemical Peeling, Carbon
            Peeling,&nbsp;
          </p>
          <h5>Varicose Veins</h5>
          <p>
            Depending on the scale of the problem and individual
            characteristics, doctors will suggest minimally invasive treatment
            (radiofrequency, laser ablation, sclerotherapy) or varicose vein
            surgery (such as mini-phlebotomy).&nbsp;
          </p>
          <h5>
            JetPeel (water, oxygen, minerals, solutions and vitamins) for the
            tre atment of: Spots, Dry skin, Deep pore (peeling), Blemish, Lines,
            Ageing.
          </h5>
        </div>

        <div className="terms-text">
          <h3>Hair Transplantation&nbsp;</h3>
          <p>
            INRUT multi implanter is designed to get&nbsp; maximum efficiency
            with minimum pain. The 1 hour procedure reduces graft loss by 90% on
            average.&nbsp;
          </p>
          <p>
            No shaving - no pain or fatigue - prompt return to normal
            routine.&nbsp;
          </p>
          <h5>Fue Hair Transplant?</h5>
          <p>Local anesthesia &ndash; No Scars &ndash; No Stains.&nbsp;</p>
          <h5>DHI Hair Transplant and</h5>
          <p>
            No scarring - No incisions &ndash; No stitches. Period of
            re-transplantation of the grafts collected is much more short with
            this technique. Thus, the probability of growing once implanted
            increases even more.&nbsp;
          </p>
          <h5>DHI Plus Gold</h5>
          <p>
            A technique redeveloped by estethica using gold-tipped needle. With
            pulses added to enhance the procedure, the patent entirely belongs
            to estethica.&nbsp;
          </p>
          <p>
            More intense image &ndash; Durable - Quick return to daily life - No
            scarring - No incisions &ndash; No stitches.&nbsp;
          </p>
          <p>
            To enhance hair growth patients can undergo Mesotherapy and
            Thermotherapy.&nbsp;
          </p>
        </div>

        <div className="terms-text">
          <h3>Dentistry and Oral Health and Pediatric Dentistry</h3>
          <p>
            Modeling of prostheses is carried out using CAD-CAM technology,
            where a model of prostheses is created in one day. Both full and
            partial dentures are available.
          </p>
          <p>
            Using the latest techniques, most dental procedures (porcelain
            lamination, dental crowning whitening, caries and periodontal
            disease treatment) are performed using laser techniques.&nbsp;
          </p>
          <h5>Veneers</h5>
          <p>
            More than one type are used to suit the mouth and tooth structure of
            patients. The generally preferred veneers are Zirconium, Emax, and
            Laminate Veneers.
          </p>
          <h5>Implants</h5>
          <p>
            The most reliable implants are used. Treatments include Mini Dental
            Implants, All On 4 Implants, and All On 6 Implants.
          </p>
          <p>
            Complex interventions by maxillofacial surgeons are performed to
            correct anomalies.
          </p>
        </div>

        <div className="terms-text">
          <h2>Alternative Medicine</h2>
          <h3>Acupuncture</h3>
          <p>
            Acupuncture has therapeutic benefits on the musculoskeletal system,
            furthermore it can greatly help with weight loss through stimulating
            the release of endorphins (reducing appetite), Regulating metabolic
            activity, Preventing side effects such as heartburn and
            increased/decreased blood sugar levels, regulating hormonal and
            enzyme activity (reducing stress).
          </p>
          <ul>
            <li>
              In Turkey, acupuncture practitioners need to be graduates of
              either a medical or dental school and need to have Competent
              Practitioner certification provided by the Ministry of Health.
            </li>
          </ul>
          <h3>Avicenna - Ibn Sina Traditional Medicine?</h3>
          <p>
            The doctor identifies the body's type of imbalance and works to
            restore balance.
          </p>
          <ol>
            <li>
              Nourishment Treatment(food, smell, sound, sight, thought, touch,
              sex and social).
            </li>
            <li>Cupping</li>
            <li>Bloodletting</li>
            <li>Leeching (Hirudotherapy).</li>
          </ol>
          <p>Healthy Living and Eating Clinic</p>
          <p>
            Tailored eating plans and education are provided for these
            conditions and many others: types 1 and 2 diabetes, Heart diseases,
            Kidney diseases, Liver diseases, Hypertension, Sportsperson
            nutrition, and ageing nutrition.
          </p>
          <ul>
            <li>
              Stomach BOTOX is a procedure that takes 15 minutes under sedation.
              It Allows weight loss between 7 and 15 kilograms in one procedure,
              and ensures that 40% of overweight is lost over a period of 6-8
              months.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EstethicaEn;
